import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseConfirmationService } from '@fuse/services/confirmation';



@Injectable()
export class ErrorNotificationService {

    constructor(
        private _fuseConfirmationService: FuseConfirmationService,
    )
    {
    }

    showError(error: any)
    {


        if (error.error){
            this.showErrorMessage(error.error);
        } else {
            this.showErrorMessage("An error occurred")
        }




    }

    showErrorMessage(error: string){
        this._fuseConfirmationService.open({
            title  : 'Error',
            message:  error,
            icon: {
                color: 'error',
                name:'error_outline'
            },
            actions: {
                confirm: {
                    show: true,
                    label:'OK',
                    color: 'accent'
                },
                cancel:{
                    show: false
                }
            }
        });
    }

}