import { enableProdMode, InjectionToken } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

import 'ag-grid-enterprise';

import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("CompanyName=Codify Pty Ltd,LicensedGroup=Codify Pty Ltd,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-020810,ExpiryDate=7_October_2022_[v2]_MTY2NTA5NzIwMDAwMA==afa480e02873ea8e675d4a3a46473e8e");

fetch('/assets/config.json')
  .then((response) => response.json())
  .then((config) => {

    if (environment.production) {
      enableProdMode()
    }

    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err))
})

export class EnvironmentConfig
{
    apiUrl: string;
}

export let APP_CONFIG = new InjectionToken<EnvironmentConfig>('APP_CONFIG')