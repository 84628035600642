import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes, AppRoutingModule } from 'app/app.routing';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MsalGuard, MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorHandlerService } from './services/error-handler.service';
import { LoggingService } from './services/logging.service';
import { ErrorNotificationService } from './services/error-notification.services';
import { MatLuxonDateModule, MAT_LUXON_DATE_FORMATS, LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ProfileComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        //RouterModule.forRoot(appRoutes, routerConfig),
        AppRoutingModule,

        MatButtonModule,
        MatToolbarModule,
        MatListModule,
        MatSnackBarModule,
        MatLuxonDateModule,

        MsalModule.forRoot( new PublicClientApplication({
        auth: {
                clientId: environment.config.clientId, // This is your client ID
                authority: environment.config.authority, // This is your tenant ID
                redirectUri: window.location.origin// This is your redirect URI
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }
            }),
            {
                interactionType: InteractionType.Redirect, // MSAL Guard Configuration
                authRequest: {
                    scopes: ['User.Read']
                }
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['User.Read']],
                    [environment.apiUrl, [environment.apiScope]] //obtain access to call the api
                ])
            }

        ),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule
    ],
    providers: [
        {

            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        { provide: DateAdapter, useClass: LuxonDateAdapter },
        {
            provide: MAT_DATE_LOCALE, useValue: 'en-AU'
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue:
            {
                parse:
                {
                    dateInput: ['d LLL yyyy']
                },
                display:
                {
                    dateInput: 'd LLL yyyy',
                    monthYearLabel: 'MMM yyyy',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM yyyy'
                }
            }
        },
        MsalGuard, // MsalGuard added as provider here
        LoggingService,
        ErrorHandlerService,
        ErrorNotificationService,
        { provide: ErrorHandler, useClass: ErrorHandlerService }

    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
