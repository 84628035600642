/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'file-feeds',
        title   : 'Agent File Feeds',
        subtitle: 'Data sourced from the data broker agents',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'filefeed.hydstra',
                title: 'Hydstra',
                type : 'basic',
                icon : 'heroicons_outline:beaker',
                link : '/agent/feed/hydstra'
            },
            {
                id   : 'filefeed.orion',
                title: 'Orion',
                type : 'basic',
                icon : 'heroicons_outline:credit-card',
                link : '/agent/feed/orion'
            },
            {
                id   : 'agentstatus',
                title: 'Agent Status',
                type : 'basic',
                icon : 'heroicons_outline:status-online',
                link : '/agent/status'
            }
        ]
    },
    {
        id      : 'extracts',
        title   : 'Extracts / Download',
        subtitle: 'Extract ad-hoc data from Data Broker and see sending history',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'extracts.ondemand',
                title: 'On-Demand Extracts',
                type : 'basic',
                icon : 'heroicons_outline:document-report',
            link : '/extract/on-demand'
            },
            {
                id   : 'extracts.ftp',
                title: 'FTP History',
                type : 'basic',
                icon : 'heroicons_outline:download',
                link : '/extract/ftp-history'
            }
        ]
    },
    {
        id      : 'orchestration',
        title   : 'Orchestration',
        subtitle: 'Create export schedules',
        type    : 'group',
        icon    : 'heroicons_outline:clock',
        children: [
            {
                id   : 'orchestration.definitions',
                title: 'Job Definitions',
                type : 'basic',
                icon : 'heroicons_outline:book-open',
                link : '/orchestration/job-definitions/list'
            },
            {
                id   : 'orchestration.runs',
                title: 'Run History',
                type : 'basic',
                icon : 'heroicons_outline:lightning-bolt',
                link : '/orchestration/job-runs/list'
            },

        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
