import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { AccountInfo, IPublicClientApplication, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';


type ProfileType = {
  givenName?: string,
  surname?: string,
  displayName? : string,
  userPrincipalName?: string,
  id?: string
};

// type AzureADProfileData = {
//     account: AzureADProfileDataAccount[],
//     names: AzureADProfileDataName[]
// };

// type AzureADProfileDataName = {
//     displayName?: string
// }

// type AzureADProfileDataAccount = {
//     id?: string,
//     userPrincipalName? : string
// }

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    private profile!: ProfileType;

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _msalService: MsalService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<void>
    {



        return of(null).pipe(
            map(
                () => {
                    const instance: IPublicClientApplication = this._msalService.instance;
                    const activeAccount: AccountInfo | null = instance.getActiveAccount();
                    const accounts: AccountInfo[] = instance.getAllAccounts();


                    if (activeAccount != null)
                    {
                        let data = {
                            id: activeAccount.localAccountId,
                            name: activeAccount.name,
                            email: activeAccount.username,
                            avatar: null,
                            status: 'Online'
                        };


                        this._user.next(data);
                        return;
                    }

                    if (accounts.length > 0) {
                        const [firstAccount] = accounts;
                        instance.setActiveAccount(firstAccount);

                        let data = {
                            id: firstAccount.localAccountId,
                            name: firstAccount.name,
                            email: firstAccount.username,
                            avatar: null,
                            status: 'Online'
                        };


                        this._user.next(data);
                        return;
                    }




                })
        );


        // return this._httpClient.get<User>('api/common/user').pipe(
        //     tap((user) => {
        //         this._user.next(user);
        //     })
        // );
    }

}
