export const environment = {
    production: false,
    config:{
        clientId: '2a92f757-275c-4eb6-8009-8b8013593eba',
        authority: 'https://login.microsoftonline.com/11491073-990d-433d-bd35-3b3be0bfba26'
    },
    apiUrl: "https://databroker-admin-api-test.sunwater.com.au",
    apiScope: "api://f85e9031-b1ab-484b-bd02-aaadcfa712ef/access_as_user",
    appInsights:{
        instrumentationKey: "80657bd0-d5d6-4224-9f6c-99c337670f89"
    }
};
